
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import { KeepAlive } from '@/utils/decorators'
import { ProductDetail, PriceAuthInfo, ProductExportInfo, ProductExportDetail } from '@/types/product.d'
import { ElForm } from 'element-ui/types/form'
@Component({
  name: 'ProductList',
  components: { ImportData }
})
@KeepAlive
export default class extends Vue {
  private supplierList: Array<{ supplierId: string; supplierName: string }> = []
  private attributeList: Array<{ attributeId: string; attributeName: string }> = []
  private productTypeList: Array<{ productTypeId: string; productTypeName: string }> = []
  private searchInfo = {
    keyWord: '',
    supplierId: '',
    attributeId: '',
    productTypeId: '',
    productSupplierLevel: '',
    verificationStatus: '',
    testReport: '',
    selectionPrice: '',
    purchasePrice: '',
    fixedPrice: ''
  }

  private loading = false
  private info: Array<ProductDetail> = []

  private total = 0
  private page = 1
  private size = 10

  private isShowImport = false
  private userList: Array<any> = []
  private priceAuthList: Array<PriceAuthInfo> = []
  private priceAuthInfo: PriceAuthInfo = {
    userId: '',
    quotaPriceStatus: '0',
    purchasePriceStatus: '0',
    typePriceStatus: '0'
  }

  private isShowPriceAuthSet = false
  private isLoadPriceAuthList = false
  private priceAuthEditLine: number | null = null

  created () {
    this.getData()
    this.getSupplierList()
    this.getAttributeList()
    this.getProductTypeList()
  }

  getSupplierList () {
    this.$axios.get(this.$apis.supplier.list).then((res) => {
      this.supplierList = res.list || []
    })
  }

  getAttributeList () {
    this.$axios.get(this.$apis.product.selectProductAttributeByPage).then((res) => {
      this.attributeList = res.list || []
    })
  }

  getProductTypeList () {
    this.$axios.get(this.$apis.product.selectProductTypeByPage).then((res) => {
      this.productTypeList = res.list || []
    })
  }

  getPriceAuthList () {
    this.isLoadPriceAuthList = true
    this.$axios.get(this.$apis.product.selectProductPriceSetByList).then((res) => {
      this.priceAuthList = res || []
    }).finally(() => {
      this.isLoadPriceAuthList = false
    })
  }

  getUserList () {
    this.$axios.get(this.$apis.product.selectCrmUserList).then((res) => {
      this.userList = res || []
    })
  }

  getData () {
    this.loading = true
    this.info = []
    this.$axios.get(this.$apis.product.selectNewProductByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.info = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'productAdd'
    })
  }

  // 导出
  onExport () {
    this.$router.push({
      name: 'productExport'
    })
  }

  // 基础设置
  onBaseSet () {
    this.$router.push({
      name: 'productBaseSet'
    })
  }

  // 价格权限设置
  onPriceAuthSet () {
    this.isShowPriceAuthSet = true
    this.getPriceRelactList()
  }

  getPriceRelactList () {
    this.getPriceAuthList()
    this.getUserList()
  }

  // 判断是否存在未保存的行
  hasEditLine () {
    if (this.priceAuthEditLine || this.priceAuthEditLine === 0) {
      this.$message.warning('请先保存正在编辑的权限')
      return false
    } else {
      return true
    }
  }

  // 添加新的一行价格权限设置
  addPriceAuthNewLine () {
    if (this.hasEditLine()) {
      this.priceAuthEditLine = this.priceAuthList.length
      this.priceAuthInfo = {
        userId: '',
        quotaPriceStatus: '0',
        purchasePriceStatus: '0',
        typePriceStatus: '0'
      }
      this.priceAuthList.push(this.priceAuthInfo)
    }
  }

  // 关闭价格权限弹框
  closePriceAuthSet () {
    this.priceAuthEditLine = null
  }

  // 点击编辑价格权限
  editPriceAuth (index: number, row: PriceAuthInfo) {
    if (this.hasEditLine()) {
      this.priceAuthEditLine = index
      this.userList.unshift({
        userId: row.userId,
        name: row.userName
      })
      this.priceAuthInfo = JSON.parse(JSON.stringify(row))
    }
  }

  savePriceAuth () {
    (this.$refs.priceAuth as ElForm).validate((valid) => {
      if (valid) {
        if (this.priceAuthInfo.typePriceStatus === '0' && this.priceAuthInfo.purchasePriceStatus === '0' && this.priceAuthInfo.quotaPriceStatus === '0') {
          this.$message.warning('请至少选一个价格权限')
        } else {
          let text = '添加成功'
          let url = this.$apis.product.insertProductPriceSet
          let info: PriceAuthInfo = {
            userId: this.priceAuthInfo.userId,
            quotaPriceStatus: this.priceAuthInfo.quotaPriceStatus,
            purchasePriceStatus: this.priceAuthInfo.purchasePriceStatus,
            typePriceStatus: this.priceAuthInfo.typePriceStatus
          }
          if (this.priceAuthInfo.setId) {
            text = '编辑成功'
            url = this.$apis.product.updateProductPriceSet
            info = { ...info, setId: this.priceAuthInfo.setId }
          }
          this.$axios.post(url, info).then((res) => {
            this.$message.success(text)
            this.priceAuthEditLine = null
            this.getPriceRelactList()
          })
        }
      }
    })
  }

  // 删除价格权限
  deletePriceAuth (row: PriceAuthInfo) {
    if (this.hasEditLine()) {
      this.$confirm('确认删除用户【' + row.userName + '】的价格权限吗？', '提示').then(() => {
        this.$axios.post(this.$apis.product.deleteProductPriceSet, {
          setId: row.setId
        }).then(() => {
          this.$message.success('操作成功')
          this.getPriceRelactList()
        })
      })
    }
  }

  // 操作记录
  onLog () {
    this.$router.push({
      name: 'productLogList'
    })
  }

  // 加载子产品详情
  loadChild (row: ProductDetail, treeNode: any, resolve: Function) {
    this.$axios.get(this.$apis.product.selectNewProductByPage, {
      parentProductId: row.productId
    }).then((res) => {
      resolve(res.list || [])
    })
  }

  // 详情
  onDetail (info: ProductDetail) {
    if (info.parentProductId && info.parentProductId !== '0') {
      this.$router.push({
        name: 'productChildDetail',
        params: { id: info.productId as string }
      })
    } else {
      this.$router.push({
        name: 'productDetail',
        params: { id: info.productId as string }
      })
    }
  }

  // 价格管理
  onPriceSet (id: string) {
    this.$router.push({
      name: 'productPrice',
      params: { id }
    })
  }

  // 编辑
  onEdit (id: string) {
    this.$router.push({
      name: 'productEdit',
      params: { id }
    })
  }

  // 删除
  onDelete (id: string) {
    this.$confirm('确认删除该产品吗？', '提示').then(() => {
      this.$axios.post(this.$apis.product.deleteNewProduct, {
        productId: id
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
